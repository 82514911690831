import { SET_THEME } from '../Constants/app'
import { Theme } from '../Constants/enums'

export const appInitalState = { theme: Theme.Dark }

const reducer = (state, action) => {
  switch (action.type) {
    case SET_THEME: {
      const { theme } = action
      return {
        theme: theme,
      }
    }
    default: {
      return state
    }
  }
}
export default reducer
