import { TextWrapper } from '../../Components/TextWrapper'

export const Experiments = () => {
  return (
    <TextWrapper>
      <h1 className="textHeading">Experiments</h1>
      <h2 className="textSubHeading">Projects I made for fun</h2>
      <br />
      <br />
      <p className="textbody">Coming soon!</p>
    </TextWrapper>
  )
}
