import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './App.scss'
import { Routes, Route } from 'react-router-dom'
import { Home } from './js/Screens/Home'
import { Blog } from './js/Screens/Blog'
import { Experiments } from './js/Screens/Experiments'
import { Shoots } from './js/Screens/Shoots'
import { MainWrapper } from './js/Components/MainWrapper'
import { AppContext } from './js/context'
import { Color, ElementType } from './js/Components/ThemePreset'

const App = () => {
  const {
    state: { theme },
  } = useContext(AppContext)

  const location = useLocation()

  useEffect(() => {
    const changeElementsColorTheme = (collection, type) => {
      Array.from(collection).forEach((element) => {
        element.style.color = Color[type][theme]
      })
    }

    document.body.style.backgroundColor = Color.Background[theme]
    document.body.style.color = Color.Text[theme]

    const linkElements = document.getElementsByTagName('a')
    changeElementsColorTheme(linkElements, ElementType.Text)

    const accentColoredTextElements =
      document.getElementsByClassName('textAccentColor')
    changeElementsColorTheme(accentColoredTextElements, ElementType.Accent)
  }, [theme, location])

  return (
    <Routes>
      <Route path="/" element={<MainWrapper />}>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:article" element={<Blog />} />
        <Route path="/experiments" element={<Experiments />} />
        <Route path="/shoots" element={<Shoots />} />
      </Route>
    </Routes>
  )
}

export default App
