import { useContext } from 'react'
import { AppContext } from '../../context'
import { SET_THEME } from '../../Constants/app'
import { Theme } from '../../Constants/enums'
import { Icon } from '../ThemePreset'

export const ButtonThemeToggle = () => {
  const {
    dispatch,
    state: { theme: currentTheme },
  } = useContext(AppContext)

  const handleToggleTheme = () => {
    const newTheme = currentTheme === Theme.Light ? Theme.Dark : Theme.Light

    dispatch({ type: SET_THEME, theme: newTheme })
  }

  const icon = Icon.Switch[currentTheme]

  return (
    <button onClick={handleToggleTheme}>
      <span>
        <img alt="Toggle theme icon" src={icon} width={30} height="auto" />
      </span>
    </button>
  )
}
