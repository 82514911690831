import classNames from 'classnames'
import './textWrapper.scss'

export const TextWrapper = (props) => {
  const { center, children } = props

  const textWrapperclasses = classNames('text-content-wrapper', {
    center: center,
  })

  return (
    <div {...props} className={textWrapperclasses}>
      {children}
    </div>
  )
}
