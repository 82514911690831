import { useEffect } from 'react'
import { TextWrapper } from '../../Components/TextWrapper'
import { PhotoCollage } from '../../Components/PhotoCollage'
import { ImageViewer } from '../../Components/ImageViewer'
import { Modal } from '../../Components/Modal'
import { useState } from 'react'

import { fetchImages } from '../../Api'

export const Shoots = () => {
  const [showModal, setShowModal] = useState(false)
  const [imageResources, setImageResources] = useState(null)
  const [currentImageData, setCurrentImageData] = useState(null)

  const handleImageClick = (imageId) => {
    const imageData = { imageId, ...imageResources[imageId] }
    setCurrentImageData(imageData)
    setShowModal(true)
  }

  const handleClose = () => {
    setCurrentImageData(null)
    setShowModal(false)
  }

  const getImageIds = () => {
    return imageResources && Object.keys(imageResources)
  }

  useEffect(() => {
    const handleFetchImages = async () => {
      const { imageResources } = await fetchImages()
      setImageResources(imageResources)
    }

    if (!imageResources) {
      handleFetchImages()
    }
  }, [imageResources])

  const navigateImage = (step) => {
    const imageIds = getImageIds()

    let newImageIndex = imageIds.indexOf(currentImageData.imageId) + step

    if (newImageIndex === -1) {
      newImageIndex = imageIds.length + step
    }

    if (newImageIndex === imageIds.length) {
      newImageIndex = 0
    }

    const prevImageId = imageIds[newImageIndex]
    const prevImageData = {
      imageId: prevImageId,
      ...imageResources[prevImageId],
    }
    setCurrentImageData(prevImageData)
  }

  return (
    <>
      <TextWrapper>
        <h1 className="textHeading">Shoots</h1>
        <p className="textBody">Film pictures I take when I'm not coding</p>
        <br />
        <br />
      </TextWrapper>
      {imageResources && (
        <PhotoCollage
          imageIds={getImageIds()}
          handleImageClick={handleImageClick}
        />
      )}
      <Modal show={showModal} handleClose={handleClose}>
        {showModal && (
          <ImageViewer
            imageData={currentImageData}
            navigateImage={navigateImage}
          />
        )}
      </Modal>
    </>
  )
}
