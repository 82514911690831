import classNames from 'classnames'
import './modal.scss'

export const Modal = (props) => {
  const { show, handleClose, children } = props

  const modalClasses = classNames('modal', { active: show })

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      handleClose()
    }
  }

  return (
    <div className={modalClasses} onClick={handleClickOutside} role="dialog">
      <div className="modalContent">{children}</div>
    </div>
  )
}
