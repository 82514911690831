import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { AppContext } from '../../context'
import { TextWrapper } from '../../Components/TextWrapper'
import { Theme } from '../../Constants/enums'
import Styles from './article.module.scss'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
  dracula as dark,
  materialLight as light,
} from 'react-syntax-highlighter/dist/esm/styles/prism'

const CodeComponent = (props) => {
  const { inline, className, children, theme } = props
  const laguangeMatch = /language-(\w+)/.exec(className || '')

  if (inline) {
    return (
      <code {...props} inline={inline.toString()} className={Styles.inlineCode}>
        {children}
      </code>
    )
  }

  return (
    <SyntaxHighlighter
      {...props}
      children={String(children).replace(/\n$/, '')}
      style={theme === Theme.Dark ? dark : light}
      customStyle={{
        fontSize: `1em`,
        fontFamily: `Roboto`,
        marginBottom: `1em`,
      }}
      language={laguangeMatch[1]}
      showLineNumbers={true}
      PreTag="div"
    />
  )
}

export const Article = (props) => {
  const {
    state: { theme },
  } = useContext(AppContext)

  const { markdownArticle } = props

  const [article, setArticle] = useState('')

  useEffect(() => {
    fetch(markdownArticle)
      .then((res) => res.text())
      .then((text) => setArticle(text))
  })

  const markdownClasses = classNames(Styles.markdown, {
    [Styles.markdown_light]: theme === Theme.Light,
    [Styles.markdown_dark]: theme === Theme.Dark,
  })

  return (
    <TextWrapper>
      <ReactMarkdown
        className={markdownClasses}
        children={article}
        components={{
          code: ({ node, ...props }) => (
            <CodeComponent {...props} theme={theme} />
          ),
          a: ({ node, children, ...props }) => (
            <a {...props} className="textAccentColor">
              {children}
            </a>
          ),
          h5: ({ node, children, ...props }) => (
            <h5 {...props} className="textAccentColor">
              {children}
            </h5>
          ),
        }}
      />
    </TextWrapper>
  )
}
