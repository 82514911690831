import { createContext, useEffect, useReducer } from 'react'
import appReducer, { appInitalState } from './reducers/app'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const { Provider } = AppContext

  const STORAGE_KEY = 'prefs'
  let storedData = {}
  try {
    storedData = JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '{}')
  } catch {
    storedData = {}
  }

  const appLocalStoreState = {
    ...appInitalState,
    ...storedData,
  }

  const [state, dispatch] = useReducer(appReducer, appLocalStoreState)

  useEffect(() => {
    const { theme } = state
    const data = { theme }
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data))
  }, [state])

  const value = { state, dispatch }
  return <Provider value={value}>{children}</Provider>
}
