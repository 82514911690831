import { useEffect } from 'react'
import './photoCollage.scss'

export const PhotoCollage = (props) => {
  const { imageIds, handleImageClick } = props

  const columnsCount = 3
  const rowsCount = Math.ceil(imageIds.length / columnsCount)

  const defaultTemplateAreas = `'b a a''b a a''b c d''e f d'`

  let templateAreas = ''

  for (let i = 0; i < rowsCount; i++) {
    const repeatedAreas = defaultTemplateAreas
      .replace('b a a', `b${i} a${i} a${i}`)
      .replace('b a a', `b${i} a${i} a${i}`)
      .replace('b c d', `b${i} c${i} d${i}`)
      .replace('e f d', `e${i} f${i} d${i}`)

    templateAreas += repeatedAreas
  }

  let currRow = 0

  useEffect(() => {
    Array.from(document.querySelectorAll('[data-bg]')).forEach(
      (imageContainer) => {
        const { clientWidth, clientHeight } = imageContainer
        const imageParams = `w_${clientWidth},h_${clientHeight},c_fill,dpr_auto,q_auto`
        const baseUrl = `https://res.cloudinary.com/dllt13mhz/image/upload/${imageParams}/v1/`

        const imageUrl = baseUrl + imageContainer.dataset.bg
        imageContainer.style.backgroundImage = `url(${imageUrl})`
      },
    )
  })

  return (
    <div
      className="grid"
      style={{
        gridTemplateAreas: `${templateAreas}`,
      }}
    >
      {imageIds.map((imageId, index) => {
        const areas = ['a', 'b', 'c', 'd', 'e', 'f']
        const areaIndex = index % 6

        const gridArea = areas[areaIndex] + currRow.toString()

        if (areaIndex === 5) {
          currRow += 1
        }

        return (
          <div
            className="imageContainer"
            onClick={() => handleImageClick(imageId)}
            data-bg={imageId}
            style={{ gridArea: gridArea }}
            key={`image-${index}`}
          ></div>
        )
      })}
    </div>
  )
}
