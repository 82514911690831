import classNames from 'classnames'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from '../../context'
import { Theme } from '../../Constants/enums'
import { TextWrapper } from '../../Components/TextWrapper'
import { Article } from '../Article'
import { articles } from '../../../articles/articles'

import Styles from './blog.module.scss'

const BlogPost = (props) => {
  const { articleNames, articleSlug } = props

  const articleExists = articleNames.includes(articleSlug)

  if (articleExists) {
    const markdownArticle = articles[articleSlug].markdown
    return <Article markdownArticle={markdownArticle} />
  }

  return (
    <TextWrapper>
      <h1 className="textSubheading">Article not found :(</h1>
    </TextWrapper>
  )
}

export const Blog = () => {
  const {
    state: { theme },
  } = useContext(AppContext)

  const { article: articleSlug } = useParams()
  const articleNames = Object.keys(articles)

  if (articleSlug) {
    return <BlogPost articleSlug={articleSlug} articleNames={articleNames} />
  }

  const articleListClasses = classNames(Styles.articleList, {
    [Styles.articleList_light]: theme === Theme.Light,
    [Styles.articleList_dark]: theme === Theme.Dark,
  })

  const ArticleList = () => {
    return (
      <div className={articleListClasses}>
        {articleNames.map((articleName, i) => {
          const articleTitle = articles[articleName].title
          const articleSummary = articles[articleName].summary

          return (
            <TextWrapper key={`text-wrapper-article-${i}`}>
              <Link className="textAccentColor" to={`${articleName}`}>
                {articleTitle}
              </Link>
              <p>{articleSummary}</p>
            </TextWrapper>
          )
        })}
      </div>
    )
  }

  return (
    <TextWrapper>
      <h1 className="textHeading">Blog</h1>
      <br />
      <br />
      <ArticleList />
    </TextWrapper>
  )
}
