import classNames from 'classnames'
import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Theme } from '../../Constants/enums'
import { Icon } from '../../Components/ThemePreset'
import { AppContext } from '../../context'

import './home.scss'
import { TextWrapper } from '../../Components/TextWrapper'

const Contact = (props) => {
  const { theme } = props

  const linkedInIcon = Icon.LinkedIn[theme]
  const mailIcon = Icon.Mail[theme]

  const contactClasses = classNames('contact', {
    contact_light: theme === Theme.Light,
    contact_dark: theme === Theme.Dark,
  })

  return (
    <div className={contactClasses}>
      <p className="textBody">Connect with me</p>
      <Link className="" to="https://www.linkedin.com/in/arnesputri/">
        <span>
          <img alt="Mail icon" src={linkedInIcon} height={30} width="auto" />
        </span>
      </Link>
      <Link className="" to="mailto:arnesputri@gmail.com">
        <span>
          <img alt="Linkedin icon" src={mailIcon} height={30} width="auto" />
        </span>
      </Link>
    </div>
  )
}

export const Home = () => {
  const {
    state: { theme },
  } = useContext(AppContext)

  const [textIndex, setTextIndex] = useState(0)

  const texts = [
    'Hej',
    'Halo',
    'Salut',
    'Hi',
    'Tjena',
    'Hola',
    'Ciao',
    'Halløj',
  ]

  useEffect(() => {
    if (textIndex === texts.length - 1) {
      setTextIndex(0)
    }
    setTimeout(() => {
      setTextIndex(textIndex + 1)
    }, 2500)
  }, [textIndex, texts.length])

  const headingText = `${texts[textIndex]}. I'm Arnes`

  return (
    <div className="home">
      <TextWrapper>
        <h1 className="textHeading">{headingText}</h1>
        <h2 className="textSubHeading">
          I'm a full-stack software developer.
          <br />
          <br />
        </h2>
        <p className="textBody">
          Currently working at{' '}
          <span>
            <a
              className="textBody"
              href="https://ablemind.co"
              target="_blank"
              rel="noreferrer"
            >
              Ablemind
            </a>
          </span>{' '}
          to better Mental Healthcare in Sweden.
        </p>
      </TextWrapper>

      <Contact theme={theme} />
    </div>
  )
}
