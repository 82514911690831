import classNames from 'classnames'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../context'
import { Link } from 'react-router-dom'
import { ButtonThemeToggle } from '../ButtonThemeToggle'
import { Icon } from '../ThemePreset'
import { Theme } from '../../Constants/enums'
import './menu.scss'

const MenuTab = (props) => {
  const { to, children } = props

  const location = useLocation()
  const { pathname } = location
  const activePath = pathname.split('/')

  const menuTabClasses = classNames({
    active: activePath === '' ? to === '/' : activePath.includes(to),
  })
  return (
    <Link className={menuTabClasses} to={to}>
      {children}
    </Link>
  )
}

export const Menu = () => {
  const {
    state: { theme },
  } = useContext(AppContext)

  const homeIcon = Icon.Logo[theme]

  const menuClasses = classNames('menu', {
    menuDark: theme === Theme.Dark,
  })

  return (
    <div className={menuClasses}>
      <MenuTab to={'/'}>
        <span>
          <img alt="Home icon" className="menu-icon" src={homeIcon}></img>
        </span>
      </MenuTab>
      <MenuTab to={'blog'}>Blog</MenuTab>
      <MenuTab to={'shoots'}>Shoots</MenuTab>
      <ButtonThemeToggle />
    </div>
  )
}
