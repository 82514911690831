import using_json_schema_to_validate_request_body_in_django from './using_json_schema_to_validate_request_body_in_django.md'
import json_schema_validator_decorator_for_api_views_in_django from './json_schema_validator_decorator_for_api_views_in_django.md'

const articles = {
  json_schema_validator_decorator_for_api_views_in_django: {
    title: 'JSONSchema Validator Decorator for API Views in Django',
    markdown: json_schema_validator_decorator_for_api_views_in_django,
    summary:
      'This article introduces the use of a custom Python decorator to streamline the validation process in Django API views.',
  },
  using_json_schema_to_validate_request_body_in_django: {
    title: 'Using JSON Schema to Validate Request Body in Django',
    markdown: using_json_schema_to_validate_request_body_in_django,
    summary:
      "JSON Schema is a vocabulary that allows you to annotate and validate JSON data. In this article, we'll explore how to use JSON Schema to validate request data in Django.",
  },
}
export { articles }
