const fetchImagesEndpoint =
  'https://res.cloudinary.com/dllt13mhz/image/list/shoots.json'

const transformImageResources = (resources) => {
  let imageResources = {}

  resources.forEach((resource) => {
    const { public_id: imageId, context } = resource

    const imageContext = context ? context.custom : undefined

    imageResources[imageId] = { imageContext }
  })

  return imageResources
}

export const fetchImages = async () => {
  try {
    const result = await fetch(fetchImagesEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const response = await result.json()

    const { resources } = response

    const imageResources = transformImageResources(resources)

    return { imageResources }
  } catch {
    return { success: false }
  }
}
