import './mainWrapper.scss'
import { Menu } from '../Menu'
import { Outlet } from 'react-router-dom'

export const MainWrapper = () => {
  return (
    <div className="main-wrapper">
      <main>
        <nav>{<Menu />}</nav>
      </main>
      <article>
        <div className="content-wrapper">
          <Outlet />
        </div>
      </article>
    </div>
  )
}
