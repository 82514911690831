import { Icon } from '../ThemePreset'
import { TextWrapper } from '../../Components/TextWrapper'

import './imageViewer.scss'

export const ImageViewer = (props) => {
  const { imageData, navigateImage } = props
  const { imageId, imageContext } = imageData

  const imageParams = 'c_fill,dpr_auto,q_auto'
  const baseUrl = `https://res.cloudinary.com/dllt13mhz/image/upload/${imageParams}/v1/`
  const imageUrl = baseUrl + imageId

  const handleArrowLeftClick = () => {
    navigateImage(-1)
  }

  const handleArrowRightClick = () => {
    navigateImage(1)
  }

  const imageCaption = imageContext ? imageContext.caption : ''
  const imageDetail = imageContext ? imageContext.film : ''

  return (
    <div className="imageViewer">
      {imageId && <img src={imageUrl} alt="Photograph" />}

      <div className="bottomNavigation">
        <button onClick={handleArrowLeftClick}>
          <img
            src={Icon.Arrow}
            alt={imageCaption}
            style={{ transform: 'rotate(180deg)' }}
          />
        </button>

        {
          <TextWrapper center>
            <p className="imageCaption">{imageCaption}</p>
            <p className="imageDetail">{imageDetail}</p>
          </TextWrapper>
        }

        <button onClick={handleArrowRightClick}>
          <img src={Icon.Arrow} alt="Arrow right button" />
        </button>
      </div>
    </div>
  )
}
