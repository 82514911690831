import { Theme } from '../../Constants/enums'
import LogoLight from '../../../img/LogoLight.svg'
import LogoDark from '../../../img/LogoDark.svg'
import SwitchLight from '../../../img/SwitchLight.svg'
import SwitchDark from '../../../img/SwitchDark.svg'
import UnderlineLight from '../../../img/UnderlineLight.svg'
import UnderlineDark from '../../../img/UnderlineDark.svg'
import ArrowLeftLight from '../../../img/ArrowLeftLight.svg'
import ArrowLeftDark from '../../../img/ArrowLeftDark.svg'
import ArrowRightLight from '../../../img/ArrowRightLight.svg'
import ArrowRightDark from '../../../img/ArrowRightDark.svg'
import LinkedInLight from '../../../img/LinkedInLight.svg'
import LinkedInDark from '../../../img/LinkedInDark.svg'
import MailLight from '../../../img/MailLight.svg'
import MailDark from '../../../img/MailDark.svg'

import Arrow from '../../../img/Arrow.svg'

import Colors from '../../../styles/_colors.scss'

export const Icon = {
  Logo: {
    [Theme.Light]: LogoLight,
    [Theme.Dark]: LogoDark,
  },
  Switch: {
    [Theme.Light]: SwitchLight,
    [Theme.Dark]: SwitchDark,
  },
  Underline: {
    [Theme.Light]: UnderlineLight,
    [Theme.Dark]: UnderlineDark,
  },
  LinkedIn: {
    [Theme.Light]: LinkedInLight,
    [Theme.Dark]: LinkedInDark,
  },
  Mail: {
    [Theme.Light]: MailLight,
    [Theme.Dark]: MailDark,
  },
  ArrowLeft: {
    [Theme.Light]: ArrowLeftLight,
    [Theme.Dark]: ArrowLeftDark,
  },
  ArrowRight: {
    [Theme.Light]: ArrowRightLight,
    [Theme.Dark]: ArrowRightDark,
  },
  Arrow: Arrow,
}

export const ElementType = {
  Background: 'Background',
  Accent: 'Accent',
  Text: 'Text',
}

export const Color = {
  [ElementType.Background]: {
    [Theme.Light]: Colors.lightBackground,
    [Theme.Dark]: Colors.darkBackground,
  },
  [ElementType.Accent]: {
    [Theme.Light]: Colors.lightAccent,
    [Theme.Dark]: Colors.darkAccent,
  },
  [ElementType.Text]: {
    [Theme.Light]: Colors.lightText,
    [Theme.Dark]: Colors.darkText,
  },
}
